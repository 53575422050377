<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            Watch and Learn
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="d-flex flex-column pa-5 align-center">
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/q8TsHVOFn4c?list=PLPEh61e-2oYfMMWvx-WefVdKhptsaluJk"
              title="Year 2 | Maths | Numbers up to 1 000"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    event: (state) => state.event.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    star: 0,
    boss: false,
    score: 0,
    countCorrect: 0,
    event_data: {},
  }),
  created() {
    console.log(this.event);
  },
  mounted() {},
  methods: {
    exit() {
      this.$router.push({
        name: "PageEventLevels",
      });
    },
    complete() {
      this.event_data = this.$_.cloneDeep(this.event);
      if (this.star == 3) {
        this.countCorrect = 9;
        this.score = Math.floor(Math.random() * (1100 - 900 + 1)) + 900;
      } else if (this.star == 2) {
        this.countCorrect = 6;
        this.score = Math.floor(Math.random() * (900 - 750 + 1)) + 750;
      } else if (this.star == 1) {
        this.countCorrect = 4;
        this.score = Math.floor(Math.random() * (750 - 600 + 1)) + 600;
      } else {
        this.countCorrect = 2;
        this.score = Math.floor(Math.random() * (650 - 100 + 1)) + 100;
      }

      if (this.boss) this.event_data.boss.isCollected = true;

      this.event_data.submission.countCorrect = this.countCorrect;
      this.event_data.submission.accuracy = this.score / 10;
      this.event_data.submission.star = this.star;
      this.event_data.submission.score = this.score;
      this.event_data.submission.submission += 1;

      this.event_data.record.attempt += 1;
      this.event_data.record.submission += 1;
      this.event_data.record.topScoreOld = this.event_data.record.topScore;
      this.event_data.record.topStarOld = this.event_data.record.topStar;
      if (this.score > this.event_data.record.topScore) {
        this.event_data.record.topScore = this.score;
        this.event_data.record.topStar = this.star;
      }

      this.event_data.gameLoot = Math.floor(Math.random() * (5 - 3 + 1) + 3);
      this.event_data.difficulty = 1;
      this.$store.commit("updateEvent", this.event_data);

      this.$router.push({
        name: "PageEventGameScore",
        params: {
          id: this.$store.state.event.data.record.levelId,
        },
      });
    },
  },
};
</script>

    <style scoped>
.list-panel {
  margin-top: 20px;
  padding: 5px 0;
}

.list-card {
  padding: 0 10px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>